'use client';

import React, { useEffect } from 'react';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
import { Image } from '@akinon/next/components/image';
import { Link } from '@theme/components';
import { twMerge } from 'tailwind-merge';

// register Swiper custom elements
register();

export default function MultiCardRowContent({ data, sliderId, classes = '' }) {
  const cards = data?.attributes?.cards;

  useEffect(() => {
    const swiperElement = document.getElementById(sliderId);

    if (!swiperElement) return;

    Object.assign(swiperElement, {
      breakpoints: {
        320: {
          slidesPerView: 1.2,
          spaceBetween: 16
        },
        425: {
          slidesPerView: 1.6,
          spaceBetween: 16
        },
        540: {
          slidesPerView: 2,
          spaceBetween: 16
        },
        640: {
          slidesPerView: 2.5,
          spaceBetween: 16
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 16
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 36
        }
      }
    });
    (swiperElement as any).initialize();
  }, [data, sliderId]);

  const renderSlides = () => {
    return cards?.map((card) => (
      <swiper-slide key={card?.kwargs?.value?.image?.url}>
        <Link href={card?.value?.link} className="w-full inline-block">
          <Image
            src={card?.kwargs?.value?.image?.url}
            alt={card?.value?.alt_text}
            width={261}
            height={415}
            className="w-full"
            imageClassName="w-full"
            placeholder="blur"
            blurDataURL={card?.kwargs?.value?.image?.url}
            loading="lazy"
          />
        </Link>
      </swiper-slide>
    ));
  };

  return (
    <div
      className={twMerge(
        `flex items-center justify-center ps-3 lg:px-8 w-full [&_#${sliderId}]:w-full [&_#${sliderId}]:h-full`,
        classes
      )}
      style={{ backgroundColor: data?.attributes?.background_color.value }}
    >
      <div className="w-full max-w-[1432px]">
        <swiper-container id={sliderId} init={false}>
          {cards && renderSlides()}
        </swiper-container>
      </div>
    </div>
  );
}
